import React from "react";
import { FaArrowDown } from "react-icons/fa/";
import { translate } from "react-i18next";

import Navbar from "./navbar";

const Header = props => {
  const { t } = props;
  return (
    <section className="hero gradientBg is-fullheight-with-navbar">
      <div className="hero-head">
        <Navbar />
      </div>
      <div className="hero-body">
        <div className="container center">
          <article className="media">
            <div className="media-content">
              <div className="content">
                <h1 className="is-uppercase is-size-1 has-text-white">
                  {t("title")}
                </h1>
                <p className="subtitle has-text-white is-size-3">
                  {t("subtitle")}
                </p>
              </div>
            </div>
          </article>
        </div>
      </div>
      <div className="hero-foot">
        <div className="container center">
          <button
            onClick={props.scrollToContent}
            aria-label="scroll"
            className="down-arrow"
          >
            <FaArrowDown />
          </button>
        </div>
      </div>
    </section>
  );
};

export default translate()(Header);
