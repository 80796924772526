import React from "react";

import Meta from "./helmet";
import Header from "./header";
import Midsection from "./midsection";
import Footer from "./footer";

import "../styles/style.scss";

class Layout extends React.Component {
  midsection = React.createRef();

  scrollToContent = e => {
    this.midsection.current.scrollIntoView({
      block: "start",
      behavior: "smooth"
    });
  };

  render() {
    return (
      <div>
        <Meta />
        <Header scrollToContent={this.scrollToContent} />
        <span ref={this.midsection} />
        <Midsection />
        <Footer />
      </div>
    );
  }
}

export default Layout;
