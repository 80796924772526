import React from "react";
import { graphql, StaticQuery } from "gatsby";
import Img from "gatsby-image";
import { translate } from "react-i18next";

const Midsection = props => {
  const { t } = props;
  return (
    <StaticQuery
      query={graphql`
        query {
          image1: file(relativePath: { eq: "insurance-dist.png" }) {
            childImageSharp {
              fixed(width: 450) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          image2: file(relativePath: { eq: "tourist-sources.png" }) {
            childImageSharp {
              fixed(width: 400) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          image3: file(relativePath: { eq: "tensorboard-losses.png" }) {
            childImageSharp {
              fixed(width: 450) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          image4: file(relativePath: { eq: "toxic-examples.png" }) {
            childImageSharp {
              fixed(width: 500) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      `}
      render={data => (
        <div>
          <section className="section">
            <div className="container" id="midsection">
              <div className="columns is-multiline is-vcentered midsection-contents">
                <div className="column is-half has-text-right pad-right">
                  <Img fixed={data.image1.childImageSharp.fixed} />
                </div>
                <div className="column is-half">
                  <article className="media">
                    <div className="media-content">
                      <div className="content has-text-left pad-left">
                        <h1 className="title is-size-4">
                          {t("data cleaning")}
                        </h1>
                        <p className="subtitle is-size-5">
                          {t("data cleaning desc")}
                        </p>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
              <div className="columns is-multiline is-vcentered">
                <div className="column is-half">
                  <article className="media">
                    <div className="media-content">
                      <div className="content has-text-right pad-right">
                        <h1 className="title is-size-4">
                          {t("data analysis")}
                        </h1>
                        <p className="subtitle is-size-5">
                          {t("data analysis desc")}
                        </p>
                      </div>
                    </div>
                  </article>
                </div>
                <div className="column is-half has-text-left pad-left">
                  <Img fixed={data.image2.childImageSharp.fixed} />
                </div>
              </div>
              <div className="columns is-multiline is-vcentered">
                <div className="column is-half has-text-right pad-right">
                  <Img fixed={data.image3.childImageSharp.fixed} />
                </div>
                <div className="column is-half">
                  <article className="media">
                    <div className="media-content">
                      <div className="content has-text-left pad-left">
                        <h1 className="title is-size-4">{t("model")}</h1>
                        <p className="subtitle is-size-5">{t("model desc")}</p>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
              <div className="columns is-multiline is-vcentered">
                <div className="column is-half">
                  <article className="media">
                    <div className="media-content">
                      <div className="content has-text-right pad-right">
                        <h1 className="title is-size-4">{t("ethical")}</h1>
                        <p className="subtitle is-size-5">
                          {t("ethical desc")}
                        </p>
                      </div>
                    </div>
                  </article>
                </div>
                <div className="column is-half has-text-left pad-left">
                  <Img fixed={data.image4.childImageSharp.fixed} />
                </div>
              </div>
            </div>
          </section>
          <section className="section">
            <div className="container center">
              <a
                className="button is-large is-link"
                href={t("navbar:contact url")}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>{t("navbar:contact")}</span>
              </a>
            </div>
          </section>
        </div>
      )}
    />
  );
};
export default translate(["midsection", "navbar"])(Midsection);
